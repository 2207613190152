.form {
  display: flex;
  flex-direction: column; // stack vertically
  > * { // select all direct-descendants (exclude children's children)
    margin-bottom: $m-size;
  }
}

.form__error {
  margin: 0 0 $m-size 0;
  font-style: italic;
}

.form__error--inline {
  color: red;
  margin: 0 0 $xs-size 0;
  font-style: italic;
}

.form__label {
  margin: 0 0 $xs-size 0;
}