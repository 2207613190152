.text-input {
  border: 1px solid #cacccd; // same border as custom datepicker
  height: 50px;
  font-size: $font-size-large;
  font-weight: 300;
  padding: $s-size;
}

.select {
  @extend .text-input; // inherit from .text-input
}

.textarea {
  @extend .text-input;
  height: 10rem;
}