.page-header {
  background: $off-white;
  margin-bottom: $l-size; // spacing bottom before filter
  padding: $l-size 0; // top and bottom padding
}

.page-header__actions {
  margin-top: $m-size;
}

.page-header__title {
  font-weight: 300;
  margin: 0;
  span {
    font-weight: 700; // bold
  }
}
