// Colors
$dark-grey: #333;
$grey: #666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;
$light-blue: #464b5e;
$bright-blue: #4cb3e7;

// font size
$font-size-xlarge: 2.5rem;
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$xs-size: 0.8rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
