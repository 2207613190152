.input-group {
  display: flex; // let items flow from left to right
  flex-direction: column; // flow from top to bottom for small screen
  margin-bottom: $m-size;
  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
    margin-bottom: $l-size;
  }
}

.input-group__item {
  margin-bottom: $s-size;
  @media (min-width: $desktop-breakpoint) {
    margin: 0 $s-size 0 0;
  }
}
