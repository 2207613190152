.show-for-mobile {
  @media (min-width: $desktop-breakpoint) {
    display: none;
  }
}

.show-for-desktop {
  // subtract is to make sure both selectors won't activate
  // at the same time
  @media (max-width: $desktop-breakpoint - .01rem) {
    display: none;
  }
}