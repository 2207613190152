.header {
  background: $dark-blue;
}

.header__content {
  align-items: center; // align items vertically in container
  display: flex; // use flex box to arrange items in contaner div
  justify-content: space-between;
  padding: $s-size 0;
}

.header__title {
  color: white;
  text-decoration: none; // remove underline
  h1 {
    margin: 0;
  }
}

.header__menu {
  color: $bright-blue;
  font-size: $font-size-xlarge;
  font-weight: 200;
  margin-left: $xs-size;
  text-decoration: none;
}

.header__menu--active {
  font-weight: 600;
}